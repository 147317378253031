
<template>
  <div class="report-view-container">
    <el-container style="height: 100%;">
      <el-header style="height: 12vmin;">
        <div class="title-container">
          <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i>
            <span class="view-name">返回</span></div>
        </div>
      </el-header>
      <el-main style="position: relative;">
        <div v-if="hasReport">
          <div v-if="study.reportFormat !== 'pdf'">
            <img
              class="report-img"
              :src="imageUrl"
              alt="无报告"
              @load="onLoad"
              @error="onError"
            />
          </div>
          <div v-if="study.reportFormat == 'pdf'">
            <div v-for="i in numPages" :key="i">
              <VuePdf
                :src="pdfUrl"
                :page="i"
                alt="无报告"
                @loaded="onLoad"
                @error="onError"
              />
            </div>
          </div>
        </div>
        <div v-show="showLoading" class="hint-container">
          <div class="hint-box">
            <Loading style="margin: 0 auto;"/>
            <div style="text-align: center;">正在加载报告</div>
          </div>
        </div>
        
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import VuePdf from 'vue-pdf'

export default {
  name: 'ReportSnapshotView',
  components: {
    Loading,
    VuePdf
  },
  props: {
    study: {}
  },
  data() {
    return {
      imageUrl: '',
      pdfUrl: '',
      hasReport: true,

      showLoading: true,
      showHint: true,
      
      numPages: 0, // 总页码
    };
  },
  created() {
    this.updateUrls();
  },
  watch: {
    study: {
      handler() {
        this.updateUrls();
      },
      deep: true
    }
  },
  methods: {
    updateUrls() {
      const basurl = `${this.study.pacsFasUrl}${this.study.rptStorageId}/getobject_inner_file.do?objectuid=${this.study.rptPath}&filename=report_1`;
      if (this.study.reportFormat === 'pdf') {
        this.pdfUrl = basurl + `.pdf`
        this.pdfUrl = VuePdf.createLoadingTask(this.pdfUrl) // 处理一下跨域
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages;
        });
      } else {
        this.imageUrl =  basurl + `.jpg`;
      }
    },
    onLoad() {
      this.showLoading = false;
      this.showHint = false;
    },
    onError() {
      this.hasReport = false;
      this.showLoading = false;
    },
    closeView() {
      this.$emit('update:reportViewVisible', false);
    }
  }
}
</script>

<style scoped>
.report-view-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f6f6;
  overflow-y: auto;
  z-index: 4;
}
.title-container {
  height: 12vmin;
  display: flex;
  align-items: stretch;
}
.title-container .back-icon {
  font-size: 6vmin;
  padding: 0 3vmin;
  display: flex;
  align-items: center;
}
.title-container .view-name {
  font-size: 4vmin;
  margin-left: 3vmin;
}
.title-container .back-icon:active {
  background-color: #eaeaea;
}
.report-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 12vmin;
  bottom: 0;
  background-color: #f6f6f6;
  font-size: 4vmin;
  text-align: left;
  overflow: auto;
}
.report-img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.hint-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hint-container .hint-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>